import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import Container from "../components/container"
import Copy from "../components/copy"
import Heading from "../components/heading"
import Image from "../components/image"
import MotionWrapper from "../components/motion-wrapper"
import Slab from "../components/slab"
import SEO from "../components/seo"

const TeamSinglePage = ({ data }) => {
  const {
    entry: [entry],
  } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Team",
      sectionLink: "/is",
      menuLinkHoverColor: "accent",
    })
  }, [updateHeaderState])

  const footerLinks = [
    {
      title: "Meet the rest of the team",
      slug: "/is",
    },
    {
      title: "Contact us",
      slug: "/contact",
    },
    {
      title: "Go back home",
      slug: "/",
    },
  ]

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper footerLinks={footerLinks}>
        <Slab first bg="primary" color="background">
          <Container>
            <Heading hLevel={1} headingSx={{ marginBottom: "0px" }} fullOpacity>
              {entry.title}
            </Heading>
            <Heading headingSx={{ marginBottom: "0px" }}>
              {entry.jobTitle}
            </Heading>
          </Container>
        </Slab>
        <Image
          {...entry.mainImage[0]}
          autoFormat={false}
          containerSx={{
            height: ["75vw", "50vw"],
            maxHeight: "60vh",
            backgroundColor: "primary",
          }}
          imageSx={{
            objectFit: "cover",
            height: "100%",
          }}
          imgixParams={
            entry.mainImage[0].hasFocalPoint
              ? `&crop=focalpoint&fp-x=${
                  entry.mainImage[0].focalPoint[0]
                }&fp-y=${entry.mainImage[0].focalPoint[1]}`
              : ``
          }
          transition
        />
        <Slab>
          <Container>
            <Copy markup={entry.body} />
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const TeamSinglePageQuery = graphql`
  query($entryId: Int) {
    craft {
      entry: entries(type: ["teamMember"], id: [$entryId]) {
        ... on craft_team_teamMember_Entry {
          title
          id
          casualName
          jobTitle
          body
          mainImage {
            url
            ... on craft_images_Asset {
              altText
              credit
              hasFocalPoint
              focalPoint
            }
          }
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`

export default TeamSinglePage
